import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="the-logo" />
        
        <br></br>
        <br></br>
        
        <p>Powered by lvl-4</p>
        
        <br></br>
        
        <a className="App-link" href="https://github.com/alaamjadi" target="_blank" rel="noopener noreferrer">Github Repository</a>

        <br></br>
        
        <a className="App-link" href="https://www.coursera.org/learner/alaamjadi" target="_blank" rel="noopener noreferrer">Coursera</a>
        
        <br></br>
        
        <a className="App-link" href="https://cyber.m4.infinityfreeapp.com" target="_blank" rel="noopener noreferrer">Cyber Topics</a>
        
        <br></br>
        
        <a className="App-link" href="https://data.m4.infinityfreeapp.com" target="_blank" rel="noopener noreferrer"> Data Analytics</a>

      </header>
    </div>
  );
}

export default App;


